import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/auth/Login.vue'
import CreateCollection from '../views/CreateCollection.vue'
import CollectionDetails from '../views/CollectionDetails.vue'
import AddSummary from '../views/AddSummary.vue'

// Route guards
import { projectAuth } from '@/firebase/config'

const requireAuth = (to, from, next) => {
  let user = projectAuth.currentUser
  if (!user) {
    next({ name: 'Login'})
  } else {
    next()
  }
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: requireAuth
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/collections/create',
    name: 'CreateCollection',
    component: CreateCollection,
    beforeEnter: requireAuth
  },
  {
    path: '/collections/:id',
    name: 'CollectionDetails',
    component: CollectionDetails,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/collections/:id/add',
    name: 'AddSummary',
    component: AddSummary,
    beforeEnter: requireAuth,
    props: true
  },
  {
    path: '/collections/:id/edit',
    name: 'EditSummary',
    component: AddSummary,
    beforeEnter: requireAuth,
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
