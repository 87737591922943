<template>
  <form @submit.prevent="handleSubmit">
    <h4>Create New Collection</h4>
    <input type="text" v-model="title" placeholder="Title" required>
    <textarea type="text" v-model="description" placeholder="Description" class="collection-description"></textarea>
    <button v-if="!isPending">Add Collection</button>
    <button v-else disabled>Adding...</button>
  </form>
</template>

<script>
import { ref } from '@vue/reactivity'
import useCollection from '@/composables/useCollection'
import { timestamp } from '@/firebase/config'
import { useRouter } from 'vue-router'
export default {
  setup() {
    const title = ref('')
    const description = ref('')
    const isPending = ref(false)
    const router = useRouter()

    const { error, addDoc } = useCollection('summary-collections')
    

    const handleSubmit = async () => {
      isPending.value = true

      const res = await addDoc({
        title: title.value,
        description: description.value,
        summaries: [],
        createdAt: timestamp()
      })
      isPending.value = false
      
      if (!error.value) {
        console.log('Collection was added')
        // Redirect to collection and add id to params
        router.push({ name: 'CollectionDetails', params: { id: res.id }})
      }
    }
    return { title, description, handleSubmit, error, isPending }
  }
}
</script>

<style scoped>
  form {
    margin-top: 50px;
  }
</style>