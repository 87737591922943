const removeLineBreaks = (text) => {
  return text.replace(/\n/g, ' ').replace(/\s+/g, ' ')
}

const toLowerCase = (text) => {
  return text.toLowerCase()
}

const toTitleCase = (text) => {
  return text.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
}

const useText = () => {
  return { removeLineBreaks, toLowerCase, toTitleCase }
}

export default useText