<template>
  <Navbar />
  <div class="content">
    <router-view/>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'

export default({
  components: { Navbar }
})
</script>

<style>
.content {

   
  }
</style>
