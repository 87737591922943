import { projectAuth } from '@/firebase/config'
import { ref } from 'vue'

const error = ref(null)
const isPending = ref(false)

const logout = async () => {
  isPending.value = true
  error.value = null

  try {
    const res = await projectAuth.signOut()
    isPending.value = false
  } catch(err) {
    error.value = err.message
    isPending.value = false
  }
}

const useLogout = () => {
  return { error, isPending, logout }
}

export default useLogout