<template>
  <div class="add-summary">
    <div class="summary"></div>
    <form @submit.prevent="addSummary">
      <!-- Profile radio buttons -->
      <input
        type="radio"
        id="andre"
        name="profile"
        value="3"
        v-model="profile"
        checked
      />
      <label for="andre">Andre historier</label>

      <input
        type="radio"
        id="prio"
        name="profile"
        value="2"
        v-model="profile"
      />
      <label for="prio">Prioriterede emner</label>

      <input type="radio" id="top" name="profile" value="1" v-model="profile" />
      <label for="top">Tophistorier</label>

      <!-- Title and category -->
      <div class="title-category">
        <input type="text" v-model="title" placeholder="Titel..." />

        <select v-model="category">
          <option
            v-for="(category, i) in categories"
            :key="category"
            :hidden="i === 0"
            >{{ category }}</option
          >
        </select>
      </div>

      <!-- Toolbar -->
      <div class="toolbar">
        <div class="btn" @click.prevent="handleInsertQuoteBreak">[...]</div>
        <div class="btn" @click.prevent="handleLineBreaks">
          Remove Line Breaks
        </div>
        <div class="btn" @click.prevent="handleLowercase">LowerCase</div>
        <div class="btn" @click.prevent="handleTitleCase">TitleCase</div>
        <div class="highlight">
          <label for="highlight-words">Vis forkortelser</label>
          <input
            type="checkbox"
            id="highlight-words"
            v-model="highlightWords"
          />
        </div>
      </div>
      <!-- <div class="spellcheck">
        <input id="spellcheck" type="checkbox" v-model="spellcheck">
        <label for="spellcheck">Stavekontrol</label>
      </div> -->

      <div class="textarea-container">
        <div class="backdrop" ref="backdrop">
          <div class="highlights" v-html="highlightedText"></div>
        </div>
        <!-- Text field -->
        <textarea
          class="summary-textarea"
          placeholder="Skriv resume..."
          @scroll="handleScroll"
          @keyup="highlightWords ? applyHighlights(summaryText) : highlightedText = ''"
          v-model="summaryText"
          ref="textarea"
          lang="da"
          :spellcheck="spellcheck"
        ></textarea>
      </div>
      <p class="word-count">Antal ord: {{ wordCount }}</p>
      <!-- Source selector -->
      <div class="sources-select">
        <select v-model="sourceMedium" class="mr2">
          <option v-for="(paper, i) in media" :key="paper" :hidden="i === 0">{{
            paper
          }}</option>
        </select>
        <select v-model="sourceDay">
          <option v-for="(day, i) in weekdays" :key="day" :hidden="i === 0">{{
            day
          }}</option>
        </select>
        <input type="text" placeholder="Side" v-model="sourcePageNumber" />
        <button @click.prevent="handleAddSource">Tilføj kilde</button>
      </div>
      <br />

      <div class="error" v-if="error">{{ error }}</div>

      <button v-if="!isPending" class="default">Gem</button>
      <button v-else>Saving..</button>
      <router-link :to="{ name: 'CollectionDetails' }">
        <button v-if="!isPending" class="ml2">Fortryd</button>
      </router-link>

      <div class="sources">
        <span v-for="(source, i) in sources" :key="source">
          <i
            @click.prevent="handleDeleteSource(i)"
            class="fas fa-trash mr1 ml2 trash"
          ></i>
          <span>{{ source.medium }}</span>
          <span v-if="source.day">, {{ source.day }}</span>
          <span v-if="source.pageNumber">, s. {{ source.pageNumber }}</span>
        </span>
      </div>
    </form>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import useText from "@/composables/useText";
import { useRouter } from "vue-router";
import { categories, media, weekdays } from "@/assets/data";
import useDocument from "@/composables/useDocument";
import getDocument from "@/composables/getDocument";
import { createUID } from "@/composables/createUID";
import { computed, watch } from "@vue/runtime-core";
export default {
  props: [
    "id",
    "sumId",
    "isEditing",
    "sumTitle",
    "sumSummary",
    "sumProfile",
    "sumCategory",
    "sumSources",
  ],
  setup(props) {
    const { document: summary } = getDocument("summary-collections", props.id);
    const { removeLineBreaks, toLowerCase, toTitleCase } = useText();
    const { updateDoc, isPending, error } = useDocument(
      "summary-collections",
      props.id
    );
    const router = useRouter();

    const textarea = ref(null); // Element reference
    const backdrop = ref(null);

    const profile = ref("3");
    const title = ref("");
    const category = ref(categories.value[0]);
    const summaryText = ref("");
    const sources = ref([]);

    const spellcheck = ref(true);
    const highlightWords = ref(false);
    const highlightedText = ref("");

    // Setup edited content
    if (props.isEditing) {
      profile.value = props.sumProfile;
      title.value = props.sumTitle;
      category.value = props.sumCategory;
      summaryText.value = props.sumSummary;
      sources.value = JSON.parse(props.sumSources);
    }

    const sourceDay = ref(weekdays.value[0]);
    const sourceMedium = ref(media.value[0]);
    const sourcePageNumber = ref(null);

    const showKeywords = ref(false);

    // Highlight words
    const words = [
      "»",
      "«",
      "hhv.",
      "mio.",
      "mia.",
      "bl.a.",
      "pct.",
      "%",
      "kr.",
      "f.eks.",
      "ca.",
      "adm.",
      "Adm.",
    ];

    const wordCount = computed(() => {
      return summaryText.value.split(/\W+/).length - 1;
    })

    const applyHighlights = (text) => {
      words.sort((a, b) => b.length - a.length);
      let replaceFromRegExp = new RegExp(
        String.raw`(?:(?!\w)|\b(?=\w))(?:${words
          .map((x) => x.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"))
          .join("|")})(?:(?<=\w)\b|(?<!\w))`,
        "g"
      );
      text = text.replace(replaceFromRegExp, "<mark>$&</mark>");

      highlightedText.value = text;
      // handleScroll();
    };

    const handleScroll = (event) => {
      let scrollTop = event.target.scrollTop;
      backdrop.value.scrollTop = scrollTop;
      let scrollLeft = event.target.scrollLeft;
      backdrop.value.scrollLeft = scrollLeft;  
    };

    watch(highlightWords, (val) => {
      if (val) {
        applyHighlights(summaryText.value);
        // handleScroll();
      } else {
        highlightedText.value = "";
      }
    });
    

    const handleLowercase = () => {
      const { selection, start, end } = getSelection();
      textarea.value.setRangeText(toLowerCase(selection), start, end, "select");
      textarea.value.focus();
      summaryText.value = textarea.value.value;
    };
    const handleTitleCase = () => {
      const { selection, start, end } = getSelection();
      textarea.value.setRangeText(toTitleCase(selection), start, end, "select");
      textarea.value.focus();
      summaryText.value = textarea.value.value;
    };
    const handleLineBreaks = () => {
      const { selection, start, end } = getSelection();
      textarea.value.setRangeText(
        removeLineBreaks(selection),
        start,
        end,
        "end"
      );
      textarea.value.focus();
      summaryText.value = textarea.value.value;
    };

    const handleInsertQuoteBreak = () => {
      textarea.value.setRangeText(
        "[...] ",
        textarea.value.selectionStart,
        textarea.value.selectionStart,
        "end"
      );
      textarea.value.focus();
      summaryText.value = textarea.value.value;
    };

    const handleAddSource = () => {
      if (sourceMedium.value === media.value[0]) {
        error.value = "Please select a medium";
        return;
      }

      if (sourceDay.value === weekdays.value[0]) {
        sourceDay.value = null;
      } else {
        sourceDay.value = sourceDay.value.toLowerCase();
      }

      sources.value.push({
        day: sourceDay.value,
        medium: sourceMedium.value,
        pageNumber: sourcePageNumber.value,
      });

      // reset form
      sourceDay.value = weekdays.value[0];
      sourceMedium.value = media.value[0];
      sourcePageNumber.value = "";
      error.value = null;
    };

    const handleDeleteSource = (i) => {
      if (confirm(`Er du sikker på du vil slette ${sources.value[i].medium}`)) {
        sources.value.splice(i, 1);
      }
    };

    const getSelection = () => {
      const start = textarea.value.selectionStart;
      const end = textarea.value.selectionEnd;
      const selection = summaryText.value.substring(start, end);
      return { start, end, selection };
    };

    //console.log('summary1',...summary.value.summaries)
    const addSummary = async () => {
      // Validation
      if (title.value === "") {
        error.value = "Indtast en titel";
        return;
      }
      if (category.value === categories.value[0]) {
        error.value = "Vælg en kategori";
        return;
      }
      if (summaryText.value === "") {
        error.value = "Skriv noget i tekstfeltet";
        return;
      }
      if (!sources.value.length) {
        error.value = "Tilføj mindst een kilde";
        return;
      }

      const newSummary = {
        title: title.value,
        category: category.value,
        profile: profile.value,
        sources: sources.value,
        summary: summaryText.value.replace(/^\s+|\s+$/g, ""),
        id: createUID(),
      };

      if (!props.isEditing) {
        console.log("Saving new summary");
        //console.log(...summary.value.summaries, newSummary)
        await updateDoc({
          summaries: [...summary.value.summaries, newSummary],
        });
      } else {
        // Find the summary we are editing
        const allSummaries = [...summary.value.summaries];

        const summaryIndex = allSummaries.findIndex(
          (x) => x.id === props.sumId
        );

        const editedSummary = {
          title: title.value,
          category: category.value,
          profile: profile.value,
          sources: sources.value,
          summary: summaryText.value,
          id: props.sumId,
        };

        allSummaries.splice(summaryIndex, 1, editedSummary);

        await updateDoc({
          summaries: [...allSummaries],
        });
      }

      // Reset form
      title.value = "";
      category.value = categories.value[0];
      profile.value = "3";
      sources.value = [];
      summaryText.value = "";
      error.value = null;

      router.push({ name: "CollectionDetails" });
    };

    // let text = "Hello I am a dog etc. »are you?«"
    // const words = ["Hello", "dog", "etc.", "»", "«"];
    // words.sort((a, b) => b.length - a.length);
    // let replaceFromRegExp = new RegExp(String.raw`(?:(?!\w)|\b(?=\w))(?:${words.map(x => x.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')).join("|")})(?:(?<=\w)\b|(?<!\w))`, 'g')
    // console.log(replaceFromRegExp)
    // text = text.replace(replaceFromRegExp, "<mark>$&</mark>")
    // document.body.innerHTML = text

    return {
      summaryText,
      isPending,
      profile,
      title,
      category,
      sourceDay,
      sourceMedium,
      sourcePageNumber,
      sources,
      showKeywords,
      addSummary,
      categories,
      textarea,
      handleLowercase,
      handleTitleCase,
      handleLineBreaks,
      handleInsertQuoteBreak,
      media,
      weekdays,
      handleAddSource,
      error,
      handleDeleteSource,
      spellcheck,
      highlightedText,
      applyHighlights,
      handleScroll,
      backdrop,
      highlightWords,
      wordCount,
    };
  },
};
</script>

<style>
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
.add-summary {
  max-width: 1000px;
  margin: 20px auto;
  box-sizing: border-box;
}
.add-summary .title-category {
  display: flex;
}
.add-summary input {
  /* width: 50%; */
  margin: 20px 0;
  display: inline-block;
  margin-right: 30px;
}
.add-summary select {
  /* width: 40%; */
  margin: 20px 0;
  padding: 5px;
  border-color: var(--secondary);
  border-radius: 5px;
}
.add-summary form {
  max-width: 100%;
  text-align: left;
}
.add-summary input[type="radio"] {
  background: red;
  align-items: left;
  margin: 0;
  display: inline;
  width: 20px;
  margin-right: 5px;
}
.add-summary label {
  margin-right: 10px;
}
.add-summary .toolbar {
  display: flex;
  margin-bottom: 20px;
  align-items: flex-start;
}
.add-summary .toolbar .btn {
  margin: 5px;
}
.add-summary .toolbar .highlight {
  display: flex;
  margin: 0 0 0 auto;
  width: 180px;
  height: 50px;
}

.add-summary .toolbar .highlight #highlight-words {
  margin: 20px 0 0 0;
}
.add-summary .toolbar .highlight label {
  flex-direction: column;
  min-width: 120px;

  display: flex;
  justify-content: center;
  text-align: right;
}

.add-summary .textarea-container,
.backdrop,
textarea {
  width: 100%;
  height: 300px;
  border-color: var(--secondary);
}

.add-summary .highlights,
.add-summary textarea {
  padding: 10px;
  line-height: 1.5em;
  font-size: 1em;
  letter-spacing: 0.05em;
  font: 'Open Sans', sans-serif;
}

.add-summary .textarea-container {
  display: block;
  position: relative;
  -webkit-text-size-adjust: none;
}
.add-summary .backdrop {
  position: absolute;
  z-index: 1;

  overflow: auto;
  pointer-events: none;
}

.add-summary .highlights {
  white-space: pre-wrap;
  word-wrap: break-word;
  color: transparent;
  border: 1px solid transparent;
}

.add-summary textarea {
  display: block;
  position: absolute;
  z-index: 2;
  margin: 0;
  background-color: transparent;
  overflow: auto;
  resize: none;
  box-sizing: border-box;
}

.add-summary mark {
  border-radius: 3px;
  color: transparent;
  background-color: #b1d5e5 !important;
}

.add-summary .sources-select {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.add-summary .sources-select select {
  flex: 2;
  margin: 0 10px 0 0;
}
.add-summary .sources-select input {
  flex: 1;
  margin: 0 10px 0 0;
  border: 1px solid var(--secondary);
}
.add-summary .sources-select .btn {
  flex: 1;
}
.add-summary .sources {
  margin-top: 20px;
}

.add-summary .trash {
  transition: all 0.2s;
  cursor: pointer;
}
.add-summary .trash:hover {
  color: var(--default);
  transform: scale(1.2);
}
.word-count {
  margin-top: 8px;
  font-size: 0.8em;
  width: 100%;
  text-align: right;
}
</style>
