import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyBbKCZjbUs93yUBGICRV-C7SqYYOZ4sfks",
  authDomain: "sumwrit.firebaseapp.com",
  projectId: "sumwrit",
  storageBucket: "sumwrit.appspot.com",
  messagingSenderId: "214091117388",
  appId: "1:214091117388:web:dc5c873a3a8133cb3055e9"
};

// init firebase
firebase.initializeApp(firebaseConfig)

// init services

const projectFirestore = firebase.firestore()
const projectAuth = firebase.auth()

// timestamp

const timestamp = firebase.firestore.FieldValue.serverTimestamp

export { projectFirestore, projectAuth, timestamp }