<template>
  <div class="home">
    <router-link :to="{ name: 'CreateCollection'}">
      <div class="btn fab">+</div>
    </router-link>
    
    <div class="error" v-if="error">{{ error }}</div>
    <div v-if="documents">
       <CardList :summaryCollections="documents"/>
    </div>
   
  </div>
</template>

<script>
import CardList from '@/components/CardList.vue'
import getCollection from '@/composables/getCollection'
export default {
  name: 'Home',
  components: { CardList },
  setup() {
    const { error, documents } = getCollection('summary-collections')

    return { error, documents }

  }
}
</script>
<style>
  .home {
    margin: 0 10px;
  }
</style>