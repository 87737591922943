<template>

  <form @submit.prevent="handleSubmit" class="login-form">
    <h2>Log ind</h2>
    <input type="email" placeholder="Email" v-model="email">
    <input type="password" placeholder="Password" v-model="password">
    <div class="error" v-if="error">{{ error }}</div>
    <button v-if="!isPending">Log ind</button>
    <button v-else disabled>Logger ind...</button>
  </form>
</template>
  
<script>
import { ref } from '@vue/reactivity'
import useLogin from '@/composables/useLogin'
import { useRouter } from 'vue-router'

export default {
  setup() {
    const email = ref('')
    const password = ref('')
    const router = useRouter()

    const { error, login, isPending } = useLogin()

    const handleSubmit = async () => {
      const res = await login(email.value, password.value)
      if (!error.value) {
        console.log('You have logged in')
        router.push({ name: 'Home' })
      }

    }

    return { email, password, handleSubmit, error, isPending }
  }
}
</script>

<style>
  .login-form {
    margin-top: 50px;
  }
  .login-form h2 {
    text-align: center;
  }
</style>