<template>
  <StatusBar v-if="collection" :collection="collection" />
  <div class="error" v-if="error">{{ error }}</div>
  <router-link :to="{ name: 'AddSummary' }">
    <div class="btn fab">+</div>
  </router-link>
  <!-- Summary list -->
  <div class="summary-list" v-if="collection">
    <div class="no-summaries" v-if="!collection.summaries.length">
      No summaries added yet!
    </div>

    <div
      v-else
      :class="{singleSummary: true, highlight:summary.id === selected}"
      v-for="summary in sortedSummaries"
      :key="summary.id"
      @click="setSummaryInfo(summary), selected = summary.id"

    >
      <div class="marker top" v-if="summary.profile === '1'"></div>
      <div class="marker prio" v-if="summary.profile === '2'"></div>
      <div class="marker other" v-if="summary.profile === '3'"></div>

      <p class="category-title">
        {{ summary.category }}: {{ summary.title }}
      </p>
      <div class="actions">
        <button @click="handleEditSummary(summary)">Redigér</button>
        <button @click="handleDeleteSummary(summary.id)" class="danger">Slet</button>
      </div>
    </div>
  </div>
  <!-- Summary Description -->
  <div class="summary-description" v-if="collection">
    <div v-if="collection.summaries.length">
      <h3 class="mb2" v-if="category">{{ category }}: {{ title }}</h3>
      <p class="mb2 description" v-html="description"></p>
      <p>
        <em>{{ sources }}</em>
      </p>
    </div>
  </div>
</template>

<script>
import getDocument from "@/composables/getDocument";
import useDocument from "@/composables/useDocument";
import sortSources from "@/composables/sortSources";
import StatusBar from "@/components/StatusBar.vue";
import { ref, computed } from "@vue/reactivity";
import { useRouter } from "vue-router";

export default {
  props: ["id"],
  components: { StatusBar },
  setup(props) {
    const { document: collection, error } = getDocument(
      "summary-collections",
      props.id
    );
    const { updateDoc } = useDocument("summary-collections", props.id);
    const router = useRouter();

    const selected = ref(null)

    const title = ref("");
    const description = ref("");
    const sources = ref("");
    const category = ref("");

    const setSummaryInfo = (summary) => {
      title.value = summary.title;
      description.value = summary.summary;
      sources.value = sortSources(summary.sources);
      category.value = summary.category;
    };
    const handleDeleteSummary = async (id) => {
      if (confirm("Are you sure?")) {
        const summaries = collection.value.summaries.filter(
          (summary) => summary.id != id
        );
        await updateDoc({ summaries: summaries });
      }
    };

    const handleEditSummary = (summary) => {
      router.push({
        name: "EditSummary",
        params: {
          sumTitle: summary.title,
          sumSummary: summary.summary,
          sumProfile: summary.profile,
          sumCategory: summary.category,
          sumId: summary.id,
          sumSources: JSON.stringify(summary.sources),
          isEditing: true,
        },
      });
    };

    // const replaceLineBreaks = computed((value) => {
    //   return value.replace(/\n/g, '<br>')
    // })

    const sortedSummaries = computed(() => {
      return collection.value.summaries.sort((a, b) => {
        if (a.profile === '1' || a.profile === '2') {
          return a.profile > b.profile ? 1 : -1;
        }
        
      });
    });

    return {
      selected,
      sortedSummaries,
      error,
      collection,
      setSummaryInfo,
      title,
      description,
      category,
      sources,
      handleDeleteSummary,
      handleEditSummary,
  
    };
  },
};
</script>

<style scoped>
.summary-list {
  width: 50%;
  height: calc(100vh - 124px);
  overflow-y: scroll;
  float: left;
  box-sizing: border-box;
}


.singleSummary {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #eee;
  background: white;
  padding: 20px;
}
.singleSummary:hover, .highlight {
  background: var(--accent);
}

.top {
  background-color: gold;
}
.prio {
  background-color: silver;
}
.other {
  background-color: #b6ebce;
}
.marker {
  min-width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid #a7a7a7;
  margin-right: 10px;
}
.no-summaries {
  text-align: center;
  font-size: 2em;
  margin-top: 50px;
  color: var(--secondary);
}
.actions {
  display:flex;
  flex-direction: column;
  margin-left: auto;
}
.actions button {
  margin: 5px;
  min-width: 100%;
}
.category-title {
  max-width: 600px;
}

.single-summary:hover {
  background: var(--accent);
  cursor: pointer;

}
.summary-description {
  width: 50%;
  box-sizing: border-box;
  padding: 20px;
  float: left;
  height: calc(100vh - 124px);
  overflow-y: scroll;
}

.summary-description .description {
  white-space: pre-wrap;
}


</style>
