<template>
  <div class="statusbar">
    
    <div class="back-btn">
      <router-link :to="{ name: 'Home'}">
      <button class="icon medium"><i class="fas fa-arrow-left"></i></button>
      </router-link>
    </div>
    <div class="title">
      <h3>{{ collection.title }}</h3>
      <p>({{ collection.summaries.length }} resuméer)</p>
    </div>
    <div class="download-btn">
      <button class="default" @click="handleDownloadCollection"><i class="fas fa-download mr2"></i>Download Word</button>
    </div>
  </div>
</template>

<script>
import downloadWordFile from '@/composables/downloadWordFile'
export default {
  props: ['collection'],
  setup(props) {
    const handleDownloadCollection = () => {
    // Deep clone object
    const downloadableCollection = JSON.parse(JSON.stringify({...props.collection}))
      console.log(downloadableCollection)
      downloadWordFile(downloadableCollection)
    }

    return { handleDownloadCollection }
  }
}
</script>

<style scoped>
.statusbar {
  display: flex;
  padding: 10px 0;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  height: 40px;
  background: #eee;
}
.statusbar p {
  font-size: 0.8em;
}
.statusbar .title {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.statusbar .back-btn {
  margin-left: 10px;
}
.statusbar .download-btn {
  margin-right: 10px;
}
.default i {
  color: #fff;
}
</style>