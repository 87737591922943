<template>
  <div class="navbar">
    <nav>
      <router-link :to="{ name: 'Home' }">
        <h1>
          <span class="logo-summary">Summary</span>
          <span class="logo-writer">Writer</span>
        </h1>
      </router-link>
      
      <div class="links">
        <div v-if="user">
          <button @click="handleClick">Log ud</button>
        </div>  
        <div v-else>
          <router-link class="btn" :to="{ name: 'Login' }">Log ind</router-link>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import useLogout from "@/composables/useLogout"
import getUser from '@/composables/getUser'
import { useRouter } from 'vue-router'

export default {
  setup() {
    const { user } = getUser()
    const { logout } = useLogout()
    const router = useRouter()

    const handleClick = async () => {
      await logout()
      router.push({ name: 'Login' })
    }
    return { handleClick, user }
  }
}
</script>

<style scoped>
  .navbar {
  padding: 16px 10px;
  /* margin-bottom: 60px; Fix on other screens */
  background: #fff;
}
nav {
  display: flex;
  align-items: center;
  margin: 0 auto;
}
nav h1 {
  margin-left: 20px;
}
nav .links {
  margin-left: auto;
}
nav .links a,
button {
  margin-left: 16px;
  font-size: 14px;
}
nav img {
  max-height: 60px;
}
span.logo-summary {
  color: teal;
  font-weight: 500

}
span.logo-writer {
  color: var(--primary);
  font-weight: 400
}


</style>