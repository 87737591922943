import { ref } from '@vue/reactivity'

const categories = ref([ "Vælg kategori...",
"Institutionelle anliggender",
"Interne anliggender",
"Retlige anliggender",
"Finansielle anliggender",
"Grundlæggende rettigheder",
"Udenrigspolitik",
"Socialpolitik",
"Naboskabspolitik",
"Arbejdsmarkedspolitik",
"Sikkerhedspolitik",
"Frihandelsaftale med USA",
"Det digitale indre marked",
"Udvidelse",
"Økonomi",
"Beskæftigelse, vækst og investeringer",
"Landbrug",
"Migration",
"Handel",
"Kultur",
"Konkurrence",
"Sundhed",
"Klima",
"Administration"
])

const media = ref([
  "Vælg medie...",
  "Politiken",
  "Berlingske",
  "B.T.",
  "Jyllands-Posten",
  "Kristeligt Dagblad",
  "Information",
  "Ekstra Bladet",
  "Børsen",
  "Altinget",
  "Weekendavisen"
])

const weekdays = ref([
  "Vælg dag...",
  "Mandag",
  "Tirsdag",
  "Onsdag",
  "Torsdag",
  "Fredag",
  "Lørdag",
  "Søndag"
])

export { categories, media, weekdays }