<template>
  <div v-for="collection in summaryCollections" :key="collection.id">
    <div class="single">
      <div class="info">
        <h3>{{ collection.title }}</h3>
        <p>{{ collection.description }}</p>
        <p class="summary-number">
          ({{ collection.summaries.length }} resuméer)
        </p>
      </div>
      <div class="details">
        <button @click="handleEdit(collection.id)">Redigér</button>
        <button class="danger" @click="handleDelete(collection.id)">
          Slet
        </button>
      </div>
    </div>
    <!-- Insert router link with params here -->
  </div>
</template>

<script>
import useDocument from "@/composables/useDocument";

import getDocument from "@/composables/getDocument";
import useCollection from "@/composables/useCollection";
import { useRouter } from "vue-router";
import { watch } from "@vue/runtime-core";
export default {
  props: ["summaryCollections"],
  setup() {
    const router = useRouter();
    const { addDoc } = useCollection("old-collections");
    // Add backup of dataset to seperate collection on deletion

    const handleDelete = async (id) => {
      if (confirm("Er du sikker?")) {
        backupCollection(id);
        // const { updateDoc } = useDocument('old-collections', id)
        const { document } = getDocument("summary-collections", id);
        console.log("Retrieved doc>", document);
        const { deleteDoc } = useDocument("summary-collections", id);
        deleteDoc();
      }
    };
    const handleEdit = (id) => {
      router.push({ name: "CollectionDetails", params: { id: id } });
    };

    const backupCollection = async (id) => {
      const { document } = getDocument("summary-collections", id);

      watch(document, (val) => {
        addDoc(val);
      });
    };
    return { handleDelete, handleEdit };
  },
};
</script>

<style scoped>
.single {
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  background: white;
  margin: 16px 0;
  transition: all ease 0.2s;
}
.single:hover {
  box-shadow: 1px 2px 3px rgba(50, 50, 50, 0.05);
  transition: all ease 0.2s;
}
.info p {
  margin: 10px 0;
}
.summary-number {
  font-size: 0.8em;
}
.details {
  display: flex;
  flex-direction: column;
  height: 100px;
  justify-content: space-around;
  margin-left: auto;
}
.details button {
  width: 100%;
}
</style>
