import { ref, watchEffect } from 'vue'
import { projectFirestore } from '../firebase/config'

const getDocument = (collection, id) => {
  const error = ref(null)
  const document = ref(null) 

  let documentRef = projectFirestore.collection(collection).doc(id)
    
  const unsub = documentRef.onSnapshot((doc) => {
    
     if (doc.exists) {
      document.value = {...doc.data(), id: doc.id}
      error.value = null
     } else {
       error.value = 'Document does not exist'
     }
    }, (err) => {
      console.log(err.message)
      error.value = 'could not fetch document'
    })
    // Remove snapshot listener on onmount
    watchEffect((onInvalidate) => {
      onInvalidate(() => {
        unsub()
      })
    })
    return { document, error }
}

export default getDocument