import { saveAs } from "file-saver";
const htmlDocx = require('@/assets/html-docx')
import sortSources from '@/composables/sortSources'
console.log(htmlDocx)

function downloadWordFile(collection) {
  let allSummaries = ''
  const header = "<html><head><meta charset='utf-8'></head><body>"
  const footer = "</body></html>"

  let topCounter = 0
  let prioCounter = 0
  let otherCounter = 0

  const collectionTitle = collection.title
  const collectionTitleFormatted = `<strong>${collection.title}</strong><br><br>`
  const collectionDescription = `<strong>${collection.description}</strong><br><br>`

  const topStoriesStr = "<strong><u/>Dagens EU-tophistorier</u></strong><br><br>"
  const prioStoriesStr = "<strong><u/>Andre EU-historier: Prioriterede emner</u></strong><br><br>"
  const otherStoriesStr = "<strong><u/>Andre EU-historier</u></strong><br><br>"

  collection = collection.summaries.sort((a, b) => {
    return a.profile > b.profile ? 1 : -1;
  })

  collection.forEach((item, i) => {
    // Check for number of different profile occurances
    if (item.profile === "1") {
      topCounter++;
    }
    if (item.profile === "2") {
      prioCounter++;
    }
    if (item.profile === "3") {
      otherCounter++;
    }

    let sources = sortSources(item.sources);
    let sourceText = "";

    if (item.sources.length > 1) {
      sourceText = "Kilder: ";
    } else {
      sourceText = "Kilde: ";
    }

    const textContent = item.summary.replaceAll("&nbsp;", "").replaceAll("<div>", "<br>").replaceAll("</div>", "").replace(/\n/g, '<br>')
    
    const fullSummary = `
      ${i === 0 ? collectionTitleFormatted : ""}
      ${i === 0 ? collectionDescription : ""}
      ${item.profile === "1" && topCounter === 1 ? topStoriesStr : ""}
      ${item.profile === "2" && prioCounter === 1 ? prioStoriesStr : ""}
      ${item.profile === "3" && otherCounter === 1 ? otherStoriesStr : ""}
      <strong>${item.category}: ${item.title}</strong><br>${textContent}<br><em>${sourceText}${sources}</em><br><br>`
   
      allSummaries += fullSummary
  })

  const allSummariesDocument = `${header}${allSummaries}${footer}`
  
  const convertedSummariesDocument = htmlDocx.asBlob(allSummariesDocument)

  saveAs(convertedSummariesDocument, collectionTitle)
}
 
export default downloadWordFile